import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { createStore } from 'redux';

class Square extends React.Component {
  render() {
    const { currentPlayer, placePiece, piecePlaced, i, finished } = this.props;

    return (
      <button disabled={finished} onClick={ placePiece.bind(this, currentPlayer, i) } className="square">
        {piecePlaced}
      </button>
    );
  }
}

class Board extends React.Component {
  componentDidUpdate(prevProps) {
    const { board, finished } = this.props;
    const boardChanged = JSON.stringify(prevProps.board) !== JSON.stringify(board);

    if(!finished) {
      if(!board.every(x => (x === undefined))) { // Unless new game
        this.updateGameStatus(boardChanged);
      }
    }
  }

  updateGameStatus(boardChanged) {
    const { board, currentPlayer, swapPlayer, setGameStatus } = this.props;
    const same = (range) => (
      board.filter((_,i) => (range.includes(i)))
        .every(p => (p === currentPlayer))
    );
    let won = false;

    if (board.filter(p => (p === currentPlayer)).length >= 3) {
      won |= same([0,1,2]) || same([3,4,5]) || same([6,7,8])  // Check rows
      won |= same([0,3,6]) || same([1,4,7]) || same([2,5,8])  // Check columns
      won |= same([0,4,8]) || same([2,4,6])                   // Check diangonals
    }

    let gameEnded = won || board.every(x => (x !== undefined));

    setGameStatus(gameEnded, (won ? currentPlayer : undefined))

    if (!gameEnded && boardChanged) {
      swapPlayer(currentPlayer)
    }
  }

  renderStatus() {
    const { finished, winner } = this.props;

    if (finished && winner !== undefined) {
      return (<div>{'Winner is ' + winner}</div>)
    }
  }

  renderRow(squares, i) {
    const { currentPlayer, placePiece, finished } = this.props;

    return (
      <div key={i} className="board-row">
        {squares.map ((square, j) => (
          <Square
            key={i+'_'+j}
            i={i*3+j}
            piecePlaced={square}
            placePiece={placePiece}
            currentPlayer={currentPlayer}
            finished={finished}
          />
        ))}
      </div>
    )
  }

  render() {
    const { board, currentPlayer, resetGame } = this.props;

    return (
      <div>
        <div className="status">Next player: {currentPlayer}</div>
        <button onClick={resetGame} >New Game</button>
        {this.renderStatus()}
        {this.renderRow(board.slice(0, 3), 0)}
        {this.renderRow(board.slice(3, 6), 1)}
        {this.renderRow(board.slice(6, 9), 2)}
      </div>
    );
  }
}

class Game extends React.Component {
  render() {
    return (
      <div className="game">
        <div className="game-board">
          <Board {...this.props} />
        </div>
      </div>
    );
  }
}



const initialGameState = {
  currentPlayer: 'X',
  finished: false,
  winner: undefined,
  board: Array(9).fill(undefined)
}

const RESET_GAME = 'RESET_GAME';
const SET_GAME_STATUS = 'SET_GAME_STATUS';
const SWAP_PLAYER = 'SWAP_PLAYER';
const PLACE_PIECE = 'PLACE_PIECE';

const reducer = (state = initialGameState, action) => {
  switch (action.type) {
    case RESET_GAME:
      return initialGameState;
    case SET_GAME_STATUS:
      return { ...state, finished: action.finished, winner: action.winner }
    case PLACE_PIECE:
      const board = state.board
      return { ...state, board: [
        ...board.slice (0, action.i),
        (board[action.i] === undefined ? action.currentPlayer : board[action.i]),
        ...board.slice (action.i + 1),
      ]};
    case SWAP_PLAYER:
      return { ...state, currentPlayer: (action.currentPlayer === 'X' ? 'O' : 'X') };
    default:
      return state;
  }
}

let store = createStore(reducer);

const mapStateToProps = state => {
  return {
    currentPlayer: state.currentPlayer,
    finished: state.finished,
    winner: state.winner,
    board: state.board
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetGame: () => dispatch({ type: RESET_GAME }),
    setGameStatus: (finished, winner) => dispatch({ type: SET_GAME_STATUS, finished, winner }),
    swapPlayer: (currentPlayer) => dispatch({ type: SWAP_PLAYER, currentPlayer }),
    placePiece: (currentPlayer, i) => dispatch({ type: PLACE_PIECE, currentPlayer, i })
  };
};

const MyGame = connect(mapStateToProps, mapDispatchToProps)(Game);

ReactDOM.render(
  <Provider store={store}>
    <MyGame />
  </Provider>,
  document.getElementById('root')
);
